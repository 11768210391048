<template>
  <div class="basket">
    <div class="title">{{ $t("cart.name") }}</div>
    <div class="goods">
      <div class="list">
        <div class="all">
          <div
            :class="`check ${checked.all ? 'checked' : ''}`"
            @click="
              () => {
                checked.all = !checked.all;
                checkAll();
              }
            "
          >
            <i class="el-icon-check"></i>
          </div>
          <span>{{ $t("tip.all") }}</span>
        </div>
        <div class="item" v-for="(val, key) in list" :key="key">
          <div class="line"></div>
          <div class="main">
            <div
              @click="check(val.cartId)"
              :class="`check ${
                checked.list.findIndex((item) => {
                  return item == val.cartId;
                }) == -1
                  ? ''
                  : 'checked'
              }`"
            >
              <i class="el-icon-check"></i>
            </div>
            <div class="img">
              <img class="regular" :src="val.cover" />
            </div>
            <div class="info">
              <div class="name">
                <span>{{ val.spuName }}</span>
                <span></span>
              </div>
              <!-- <div class="sub" style="margin-bottom: 16px">{{ val.spuName }}</div> -->
              <div class="sub">
                {{ val.specInfo ? val.specInfo.replace(/Talla:/g, "") : "" }}
              </div>
              <div class="bottom">
                <div class="price">
                  <span>€{{ val.price }}</span>
                  <!-- <span>{{
                    val.originPrice == "0.00" ? "" : "€" + val.originPrice
                  }}</span> -->
                </div>
                <div class="edit">
                  <div class="count">
                    <i class="el-icon-minus" @click="change(key, -1)"></i>
                    <div class="val">{{ val.num }}</div>
                    <i class="el-icon-plus" @click="change(key, 1)"></i>
                  </div>
                  <div class="btn">
                    <!-- <span>{{$t('')}}</span><hr> -->
                    <span @click="delItem(key)">{{
                      $t("address.delete")
                    }}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="total">
        <div class="totalPrice block">
          <div class="count">
            {{ $t("cart.total") }} {{ checked.list.length }}
          </div>
          <div class="line"></div>
          <div class="item">
            <span style="font-family: Regular"
              >{{ $t("cart.subtotal") }}
              <span style="color: #9d9d9c; font-size: 12px"
                >(iva incluido)</span
              ></span
            >
            <span style="font-family: Regular">€{{ cart.totalAmount }}</span>
          </div>
          <div class="item">
            <span style="font-family: Regular">{{ $t("cart.coupon") }}</span>
            <span style="font-family: Regular">-€{{ cart.totalRebate }}</span>
          </div>
          <div class="item">
            <span style="font-family: Regular">{{ $t("tip.postFee") }}</span>
            <span style="font-family: Regular">€{{ cart.expressFee }}</span>
          </div>
          <div class="line" style="margin-top: 20px"></div>
          <div class="item">
            <span style="font-family: Semibold">{{
              $t("cart.grandTotal")
            }}</span>
            <span style="font-family: Semibold">€{{ cart.totalAmount }}</span>
          </div>
          <div class="submit" @click="ensure" style="font-family: Semibold">
            {{ $t("cart.checkout") }}
          </div>
        </div>
        <!-- <div class="block">
          <div class="count">{{ $t("tip.supCenter") }}</div>
          <div class="line"></div>
          <div class="item">
            <span>{{ $t("tip.supPhone") }}：000 000 0000</span>
          </div>
          <div class="item">
            <span>{{ $t("tip.m2s") }}：10:00AM-6:00PM</span>
          </div>
        </div> -->
        <div class="block">
          <div class="count">{{ $t("tip.pmethod") }}</div>
          <div class="line"></div>
          <div class="item">
            <span>{{ $t("tip.pmethod_tip") }}</span>
          </div>
          <div class="ways">
            <div class="item">
              <img src="../../assets/icon/visa.png" />
              <span>Visa</span>
            </div>
            <div class="item">
              <img src="../../assets/icon/redsys.png" />
              <span>Redsys</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Api from "../../api/request.js";
export default {
  name: "Basket",
  components: {},
  data() {
    return {
      checked: {
        all: false,
        list: [],
      },
      list: [],
      cart: {
        minOrderAmount: 0,
        total: 0,
        totalAmount: 0,
        totalRebate: 0,
        expressFee: 0,
      },
    };
  },
  watch: {
    "checked.list": {
      handler(res) {
        if (res.length == this.cart.total) {
          this.checked.all = true;
        } else {
          this.checked.all = false;
        }
        this.calculate();
      },
      deep: true,
    },
    list(val) {
      if (val.length !== 0) {
        this.checked.all = true;
        this.checkAll();
      }
    },
  },
  created() {
    this.getList();
  },
  methods: {
    check(id) {
      //单选
      var index = this.checked.list.findIndex((item) => {
        return item == id;
      });
      if (index == -1) {
        this.checked.list.push(id);
      } else {
        this.checked.list.splice(index, 1);
      }
    },
    checkAll() {
      //全选
      if (this.checked.all) {
        this.checked.list = this.list.reduce((arr, item) => {
          return (arr = arr.concat([item.cartId]));
        }, []);
      } else {
        this.checked.list = [];
      }
    },
    getList() {
      //拉取列表数据
      // this.$loading.start();
      Api.Cart.list({}).then((res) => {
        this.cart.total = res.data.data.total;
        this.list = res.data.data.list;
        // this.$loading.done();
        this.$bus.$emit("getCartNum");
      });
    },
    update(dataList) {
      this.$dialog.loading();
      Api.Cart.update({
        data: JSON.stringify(dataList),
      }).then((res) => {
        this.$dialog.close();
        if (res.data.status == "SUCCESSS") {
          this.getList();
        }
      });
    },
    change(key, change) {
      console.log(this.list[key].num, change);
      if (this.list[key].num == 1 && change == -1) {
        this.$message.error("Cantidad mínima 1");
      } else {
        this.list[key].num = parseInt(this.list[key].num) + change;
        let dataList = [];
        this.list.forEach((item) => {
          dataList.push({
            cart_id: item.cartId,
            sku_id: item.skuId,
            num: item.num,
          });
        });
        this.update(dataList);
      }
    },
    delItem(key) {
      this.$dialog.loading();
      Api.Cart.del({
        cart_id: this.list[key].cartId,
      }).then((res) => {
        this.$dialog.close();
        if (res.data.status == "SUCCESSS") {
          this.$dialog.notify(this.$t("address.deleteSuccess"));
          this.getList();
        }
      });
    },
    calculate() {
      if (this.checked.list.length == 0) {
        this.cart.totalAmount = 0;
        this.cart.totalRebate = 0;
        this.cart.expressFee = 0;
      } else {
        var param = {
          carts: JSON.stringify(this.checked.list),
        };
        this.$dialog.loading();
        Api.Order.ensure(param).then((res) => {
          if (res.data.status == "SUCCESSS") {
            this.cart.totalAmount = res.data.data.totalAmount;
            this.cart.totalRebate = res.data.data.allRebate;
            this.cart.expressFee = res.data.data.expressFee;
          } else {
            this.$router.push("/Address");
          }
          this.$dialog.close();
        });
      }
    },
    ensure() {
      if (this.checked.list.length == 0) {
        this.$dialog.message("Please select the item to be settled");
      } else {
        var arr = [];
        this.checked.list.map((item) => {
          arr.push(
            this.list.find((res) => {
              return res.cartId == item;
            })
          );
        });
        sessionStorage.setItem("cart", JSON.stringify(arr));
        this.$router.push("/Ensure");
      }
    },
  },
};
</script>

<style lang="less" scoped>
.basket {
  overflow: hidden;
}

.check {
  width: 20px;
  height: 20px;
  border: 1px solid #757575;
  margin-right: 20px;
  cursor: pointer;
  text-align: center;
  line-height: 25px;

  & > i {
    opacity: 0;
    font-size: 18px;
    color: #fff;
    font-weight: bolder;
    transform: scale(0.1);
    transition: all 0.2s;
  }

  &.checked {
    background: #000;

    & > i {
      opacity: 1;
      transform: scale(1);
    }
  }
}

.title {
  font-size: 28px;
  font-weight: 600;
  line-height: 40px;
  margin: 40px 0;
  text-align: center;
}

.goods {
  width: 1300px;
  margin: 0 auto 213px;
  display: flex;

  .list {
    flex: 1;

    .all {
      display: flex;
      align-items: center;
      margin-bottom: 20px;

      & > span {
        font-size: 14px;
        font-weight: 400;
      }
    }

    .item {
      .line {
        height: 1px;
        background: #000;
      }

      .main {
        margin: 40px 0;
        display: flex;

        .img {
          width: 170px;
          height: 230px;
          background: #d8d8d8;
          margin-right: 20px;
        }

        .info {
          flex: 1;
          position: relative;

          .name {
            display: flex;
            justify-content: space-between;
            font-size: 14px;
            font-weight: bold;
            color: #333333;
            line-height: 14px;

            & > span:last-child {
              font-weight: 400;
            }
          }

          .sub {
            font-size: 14px;
            font-weight: 400;
            color: #999999;
            line-height: 20px;
            margin-top: 11px;
          }

          .bottom {
            position: absolute;
            display: flex;
            align-items: center;
            justify-content: space-between;
            left: 0;
            right: 0;
            bottom: 0;

            .price {
              font-size: 18px;
              font-weight: bold;
              color: #000000;
              line-height: 25px;

              // &>span:last-child {
              // 	font-size: 14px;
              // 	font-weight: 400;
              // 	line-height: 20px;
              // 	margin-left: 18px;
              // 	text-decoration: line-through;
              // }
            }

            .edit {
              display: flex;
              align-items: center;

              .count {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 106px;
                height: 30px;
                border: 1px solid #979797;

                & > i {
                  font-size: 12px;
                  cursor: pointer;
                }

                .val {
                  width: 52px;
                  font-size: 12px;
                  font-weight: 400;
                  text-align: center;
                }
              }

              .btn {
                display: flex;
                align-items: center;
                margin-left: 40px;

                & > span {
                  font-size: 14px;
                  cursor: pointer;

                  &:hover {
                    font-weight: bold;
                  }
                }

                & > hr {
                  height: 11px;
                  margin: 0 0.5em;
                }
              }
            }
          }
        }
      }
    }
  }

  .total {
    width: 400px;
    margin-left: 30px;
    padding-top: 42px;

    .block {
      padding: 0 20px 20px;
      background: #f2f2f4;

      &.totalPrice {
        background: #123178;
        color: #fff;

        .line {
          background: #fff;
        }

        .submit {
          height: 48px;
          background: #ffffff;
          border-radius: 6px;
          font-size: 16px;
          font-weight: 600;
          color: #123178;
          line-height: 48px;
          text-align: center;
          margin-top: 20px;
          cursor: pointer;

          &:hover {
            background-color: #efefef;
          }
        }
      }

      .count {
        height: 60px;
        line-height: 60px;
        font-size: 14px;
        font-weight: 400;
        text-align: center;
        font-weight: bold;
      }

      .line {
        height: 1px;
        background: #000;
      }

      & > .item {
        font-size: 14px;
        font-weight: 400;
        line-height: 20px;
        margin-top: 20px;
        display: flex;
        justify-content: space-between;
      }

      .ways {
        display: flex;
        margin-top: 20px;

        .item {
          display: flex;
          align-items: center;
          margin-right: 40px;

          & > img {
            display: block;
            height: 20px;
            margin-right: 12px;
          }

          & > span {
            font-size: 14px;
            font-weight: 400;
          }

          &:last-child {
            margin-right: 0;
          }
        }
      }
    }
  }
}

@media screen and (max-width: 500px) {
  .title {
    font-size: 4vw;
    font-weight: 600;
    line-height: 5vw;
    margin: 3vw 0;
    text-align: center;
  }

  .goods {
    width: 94vw !important;
    padding: 0 3vw;
    display: flex;
    flex-direction: column;
    margin-bottom: 30px;

    .list .item .main .img {
      width: 140px;
      height: auto;
    }

    .list .item .main .info .bottom {
      position: relative;
      justify-content: flex-start;
    }

    .total {
      width: auto;
      margin-left: 0px;
    }

    .list .item .main {
      display: flex;
      flex-direction: column;
    }
  }
}
</style>
